import { throttle } from 'underscore';

require('./cards');

const progress = document.querySelector('.js-intro-progress');
const heroBg = document.querySelector('.c-hero__bg');
const videoIntro = document.querySelector('.js-video-intro');
// const play = document.querySelector('.c-vctrl__play');
// const pause = document.querySelector('.c-vctrl__pause');
const volumeOn = document.querySelector('.c-vctrl__volume-on');
const volumeOff = document.querySelector('.c-vctrl__volume-off');

function playIntro() {
  heroBg.classList.add('c-animation--fade-out');
  progress.style.display = 'none';
  volumeOff.style.display = 'block';
  videoIntro.currentTime = 62;
  videoIntro.play();
}

function isMobile() {
  return window.matchMedia('only screen and (max-width: 1160px)').matches;
}

function loadVideo(el) {
  // function onProgress(event) {
  //   if (event.lengthComputable) {
  //     const completion = (event.loaded / event.total) * 100;
  //     console.log(`Progress ${completion}%`);
  //     displayProgress(completion);
  //   }
  // }

  function onLoad(event) {
    const blob = new Blob([event.target.response], {
      type: 'video/mp4'
    });
    el.type = 'video/mp4';
    el.src = URL.createObjectURL(blob);
    playIntro();
  }

  const url = el.querySelector('source').getAttribute('data-src');

  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhr.responseType = 'arraybuffer';
  // xhr.onprogress = onProgress;
  xhr.onload = onLoad;
  xhr.send();
}

function muteVideo(mute) {
  if (mute) {
    videoIntro.muted = true;
    volumeOn.style.display = 'none';
    volumeOff.style.display = 'block';
  } else {
    videoIntro.muted = false;
    volumeOff.style.display = 'none';
    volumeOn.style.display = 'block';
  }
}

function createSlider(slider, interval, delay) {
  let currentSlide = 0;

  if (!slider) return;

  const bgSlides = slider.querySelectorAll('.c-slide');
  if (bgSlides.length > 0) {
    bgSlides.forEach(slide => {
      slide.style.display = 'flex';
    });

    setInterval(() => {
      bgSlides.forEach(slide => {
        slide.style.opacity = 0;
      });

      currentSlide = currentSlide !== bgSlides.length - 1 ? currentSlide + 1 : 0;

      if (delay > 0 && delay < interval) {
        setTimeout(() => {
          bgSlides[currentSlide].style.opacity = 1;
        }, delay);
      } else {
        bgSlides[currentSlide].style.opacity = 1;
      }
    }, interval);
  }
}

// создаем слайдеры для героя на главной странице
createSlider(document.querySelector('.c-hero__bg'), 7000);
createSlider(document.querySelector('.c-slider'), 7000, 1500);

// загружаем и воспроизводим видео
if (videoIntro && !isMobile()) {
  volumeOff.addEventListener('click', () => {
    muteVideo(false);
  });

  volumeOn.addEventListener('click', () => {
    muteVideo(true);
  });

  window.addEventListener(
    'scroll',
    throttle(() => {
      if (window.pageYOffset > 600) {
        videoIntro.pause();
      } else {
        videoIntro.play();
      }
    }, 10)
  );

  loadVideo(videoIntro);
}
